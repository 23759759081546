import React from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { receiveTickets } from '@ttstr/actions';
import { getLocationFromProduct } from '@ttstr/reducers/locations';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { AppState } from '@ttstr/reducers';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { ContentrArea } from '@ttstr/components';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets, locations } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    receiveTickets();
  }, []);

  return (
    <article>
      <Helmet>
        <title>Bilderbuch</title>
        <body className="landing-page" />
      </Helmet>

      <Container
        fluid
        className="m-0 align-items-center justify-content-center d-flex text-center pt-0 mh-100 flex-column"
      >
        <a href="/tickets" className="">
          <img
            src={require('./assets/images/spdeluxe.jpg')}
            alt="Bilderbuch"
            className="img-fluid mb-2 mt-5 header-image"
          />
        </a>
      </Container>

      <Container
        fluid
        className="m-0 align-items-center justify-content-center d-flex text-center pt-0 px-5 mh-100 flex-column"
      >
        <a href="https://www.youtube.com/watch?v=hleTkPAghk0" className="">
          <img
            src={require('./assets/images/digitales-wunder.jpg')}
            alt="Bilderbuch"
            className="img-fluid mb-2 mt-5 pt-5 header-image"
          />
        </a>
        <div className="justify-content-center align-items-center mt-3 mb-5">
          {/* <Link to="/products?categoryId=3906" className="btn btn-outline-light mr-1 text-uppercase">
            VINYL, CD, MC
          </Link> */}
          <a href="https://www.youtube.com/watch?v=hleTkPAghk0" className="btn btn-outline-light text-uppercase">
            Auf YouTube ansehen
          </a>
        </div>
      </Container>

      <Container
        fluid
        className="m-0 align-items-center justify-content-center d-flex text-center pt-0 px-5 mh-100 flex-column"
      >
        <a href="https://www.youtube.com/watch?v=qZWCGwtlGL0" className="">
          <img
            src={require('./assets/images/dino.jpg')}
            alt="Bilderbuch"
            className="img-fluid mb-2 mt-5 pt-5 header-image"
          />
        </a>
        <div className="justify-content-center align-items-center mt-3 mb-5">
          {/* <Link to="/products?categoryId=3906" className="btn btn-outline-light mr-1 text-uppercase">
            VINYL, CD, MC
          </Link> */}
          <a href="https://www.youtube.com/watch?v=qZWCGwtlGL0" className="btn btn-outline-light text-uppercase">
            Auf YouTube ansehen
          </a>
        </div>
      </Container>
      <Container
        fluid
        className="m-0 align-items-center justify-content-center d-flex text-center pt-0 px-5 mh-100 flex-column"
      >
        <img src={require('./assets/images/softpower.png')} alt="Bilderbuch" className="img-fluid mb-2 header-image" />
        <div className="justify-content-center align-items-center mt-3 mb-5">
          {/* <Link to="/products?categoryId=3906" className="btn btn-outline-light mr-1 text-uppercase">
            VINYL, CD, MC
          </Link> */}
          <a href="http://recordjet.promo.li/Softpower" className="btn btn-outline-light text-uppercase">
            Jetzt streamen
          </a>
        </div>
      </Container>
      {/* <Container fluid className="m-0 p-0 align-items-center justify-content-center d-flex text-center intro-container">
        <p className="fw-bold">
          <strong>GELB IST DAS FELD</strong>
        </p>
        <p className="mb-0">
          <strong>DIGITAL</strong> 08 | 04 | 2022
          <br />
          <strong>VINYL, CD, MC</strong> 29 | 04 | 2022
        </p>

        <img src={require('./assets/images/cover.jpg')} alt="Bilderbuch" className="img-fluid mb-5 mt-5" />

        <p>TRACKLIST</p>
        <p className="mb-0">
          Bergauf
          <br />
          For Rent
          <br />
          Dates
          <br />
          Nahuel Huapi
          <br />
          Daydrinking
          <br />
          Schwarzes Karma
          <br />
          Klima
          <br />
          Baby, dass du es weißt
          <br />
          Blütenstaub
          <br />
          I’m Not Gonna Lie
          <br />
          Golden Retriever
          <br />
          La Pampa
          <br />
          Ab und Auf
          <br />
          Zwischen deiner und meiner Welt
          <br />
          Gelb ist das Feld
        </p>


      </Container> */}

      {/* <Container
        id="tickets"
        fluid
        className="m-0 p-0 align-items-center justify-content-center d-flex live-container text-center"
      >
        <h2 className="mb-5 mt-5 fw-bold">
          <strong>LIVE</strong>
        </h2>
        <p> */}
      {/* 30.06.2022 - Graz, Kasematten Open Air - SOLD OUT
          <br />
          01.07.2022 - Graz, Kasematten Open Air - SOLD OUT
          <br />
          02.07.2022 - Graz, Kasematten Open Air - SOLD OUT
          <br />
          09.07.2022 - Arlesheim, Stimmen Festival -{' '}
          <strong>
            <a href="https://burghof.reservix.de/p/reservix/event/1929019">TICKETS</a>
          </strong>
          <br />
          15.07.2022 - Wien, Rolling Stones Support -{' '}
          <strong>
            <a href="https://www.oeticket.com/artist/the-rolling-stones/">TICKETS</a>
          </strong>
          <br />
          21.07.2022 - Jena, Kulturarena -{' '}
          <strong>
            <a href="https://tickets.vibus.de/00100175000000/shop/vstdetails.aspx?VstKey=10017500003914000">TICKETS</a>
          </strong>
          <br />
          22.07.2022 - Karlsruhe, Das Fest -{' '}
          <strong>
            <a href="https://www.eventim.de/artist/das-fest/">TICKETS</a>
          </strong>
          <br />
          24.07.2022 - Cuxhaven, Deichbrand -{' '}
          <strong>
            <a href="https://www.eventim.de/artist/deichbrand-festival/deichbrand-festival-2022-festivaltickets-21-24-juli-2022-2788710/?affiliate=DEI">
              TICKETS
            </a>
          </strong>
          <br />
          29.07.2022 - Knyphausen, Heimspiel -{' '}
          <strong>
            <a href="https://knyphausen.reservix.de/events">TICKETS</a>
          </strong>
          <br />
          20.08.2022 - St. Pölten, Frequency Festival -{' '}
          <strong>
            <a href="https://www.frequency.at/">TICKETS</a>
          </strong>
          <br />
          16.09.2022 - Berlin, 25 Jahre radioeins -{' '}
          <strong>
            <a href="https://www.eventim.de/event/25-jahre-radioeins-waldbuehne-berlin-15194152/">TICKETS</a>
          </strong>
          09.06.2023 - Nickelsdorf, Nova Rock -{' '}
          <strong>
            <a href="https://www.novarock.at/nova-rock-2023-tickets/">TICKETS</a>
          </strong>
          <br />
          10.06.2023 - Stadt Ohne Meer Festival, Gießen -{' '}
          <strong>
            <a href="https://www.stadt-ohne-meer.de/">TICKETS</a>
          </strong>
          <br />
          11.06.2023 - Kimiko Festival, Aachen -{' '}
          <strong>
            <a href="https://kimiko-festival.de/">TICKETS</a>
          </strong>
          <br />
          25.06.2023 - Kessel Festival, Stuttgart -{' '}
          <strong>
            <a href="https://kesselfestival.de/">TICKETS</a>
          </strong>
          <br />
          29.07.2023 - Clam, Burg Clam Open Air -{' '}
          <strong>
            <a href="https://www.oeticket.com/event/bilderbuch-clam-burgarena-15916765/">TICKETS</a>
          </strong>
          <br />
          03.08.2023 - Appletree Garden Festival, Diepholz -{' '}
          <strong>
            <a href="https://www.tixforgigs.com/Event/45758">TICKETS</a>
          </strong>
          <br />
          04.08.2023 - Szene Open Air, Lustenau -{' '}
          <strong>
            <a href="https://www.szeneopenair.at/">TICKETS</a>
          </strong>
          <br />
          13.08.2023 - Taubertal Festival -{' '}
          <strong>
            <a href="https://taubertal-festival.de/">TICKETS</a>
          </strong>
          <br />
          19.08.2023 - Musikfestwochen Winterthur -{' '}
          <strong>
            <a href="https://musikfestwochen.ch/acts/bilderbuch-at/">TICKETS</a>
          </strong>
          <br />
          02.09.2023 - Dresden, Rinne w/ Kraftklub -{' '}
          <strong>
            <a href="https://krasserstoff.com/events/kraftklub-kargo-open-airs-2023-rinne-festwiese-im-ostragehege">
              TICKETS
            </a>
          </strong>
          <br />
          10.09.2023 - Lollapalooza Berlin -{' '}
          <strong>
            <a href="https://www.lollapaloozade.com/">TICKETS</a>
          </strong>
          <br />
          06.12.2023 - Muffathalle, München - <strong className="text-danger">SOLD OUT</strong>
          <br />
          07.12.2023 - Posthalle, Würzburg -{' '}
          <strong>
            <a href="/product/61125/bilderbuch-posthalle-wuerzburg-am-07-12-2023">TICKETS</a>
          </strong>
          <br />
          08.12.2023 - Bergfestival Saalbach / Hinterglemm -{' '}
          <strong>
            <a href="https://www.berg-festival.com/">TICKETS</a>
          </strong>
          <br />
          22.03.2024 - Wiesbaden, Schlachthof Wiesbaden -{' '}
          <strong>
            <a href="https://bilderbuch-musik.at/product/61754/bilderbuch-schlachthof-wiesbaden-wiesbaden-am-22-03-2024">
              TICKETS
            </a>
          </strong>
          <br />
          23.03.2024 - Leipzig, Felsenkeller - <strong className="text-danger">SOLD OUT</strong>
          <br />
          24.03.2024 - Berlin, Columbiahalle -{' '}
          <strong>
            <a href="https://bilderbuch-musik.at/product/61751/bilderbuch-columbiahalle-berlin-am-24-03-2024">
              TICKETS
            </a>
          </strong>
          <br />
          26.03.2024 - Amsterdam, Melkweg -{' '}
          <strong>
            <a href="https://www.ticketmaster.nl/event/297729">TICKETS</a>
          </strong>
          <br />
          27.03.2024 - Paris, Le Petit Bain -{' '}
          <strong>
            <a href="https://www.gdp.fr/fr_FR/artistes/bilderbuch">TICKETS</a>
          </strong>
          <br />
          28.03.2024 - London, Oslo Hackney -{' '}
          <strong>
            <a href="https://www.axs.com/uk/events/509409/bilderbuch-tickets?skin=aegpresentsuk">TICKETS</a>
          </strong>
          <br />
          16.04.2024 - Zürich, Kaufleuten -{' '}
          <strong>
            <a href="https://kaufleuten.ch/event/bilderbuch-konzert/">TICKETS</a>
          </strong>
          <br />
          17.04.2024 - Innsbruck, Live Music Hall -{' '}
          <strong>
            <a href="https://bilderbuch-musik.at/product/62611/bilderbuch-music-hall-innsbruck-am-17-04-2024">
              TICKETS
            </a>
          </strong>
          <br />
          21.06.2024 - München, Tollwood -{' '}
          <strong>
            <a href="https://bilderbuch-musik.at/product/61748/bilderbuch-tollwood-muenchen-am-21-06-2024">TICKETS</a>
          </strong>
          <br />
          17.08.2024 - Witten, Zeltfestival Ruhr -{' '}
          <strong>
            <a href="https://bilderbuch-musik.at/product/62229/bilderbuch-zeltfestival-ruhr-witten-am-17-08-2024">
              TICKETS
            </a>
          </strong>
        </p>
      </Container> */}

      <Container
        id="tickets"
        fluid
        className="m-0 p-0 align-items-center justify-content-center d-flex live-container text-center"
      >
        <h2 className="mb-5 mt-5 fw-bold">
          <strong>LIVE</strong>
        </h2>
        <p>
          {loading ? (
            <span>loading...</span>
          ) : (
            tickets.map((t) => (
              <>
                {('0' + t.valid_start_on.getDate()).slice(-2) +
                  '.' +
                  ('0' + (t.valid_start_on.getMonth() + 1)).slice(-2) +
                  '.' +
                  t.valid_start_on.getFullYear()}
                {' - '}
                {locations[t.location_id].city}
                {', '}
                {locations[t.location_id].title}
                {' - '}
                {t.sold_out_status === 'sold_out' || t.id === 61749 || t.id === 63131 ? (
                  <strong className="text-danger">SOLD OUT</strong>
                ) : t.sold_out_status === 'starts_soon' ? (
                  <strong>
                    <Link className="text-danger" to={`/product/${t.id}`}>
                      STARTING SOON
                    </Link>
                  </strong>
                ) : (
                  <strong>
                    <Link to={`/product/${t.id}`}>TICKETS</Link>
                  </strong>
                )}
                <br />
              </>
            ))
          )}
        </p>
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  const { locations } = state.Locations;
  return {
    tickets,
    loading,
    locations,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(Home);
